import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, number, object, optional, string } from "valibot";
import { rootRoute } from "../__root";

const searchSchema = object({
  token: fallback(optional(string(), ""), ""),
  stepIdx: fallback(optional(number(), 0), 0),
});

export const newAccountRoute = createRoute({
  path: "new-account",
  getParentRoute: () => rootRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotSearchValidator(searchSchema),
  beforeLoad: ({ search }) => {
    return {
      token: search.token,
    };
  },
  loader: ({ context }) => {
    if (!context.token) {
      throw redirect({
        to: "/",
      });
    }
  },
});
