import { createGlobalState, useGlobalState } from "@givenwell/hooks";

export const installPromptState = createGlobalState<BeforeInstallPromptEvent | undefined>(undefined);

export function listenForInstallPrompt() {
  window.addEventListener("beforeinstallprompt", e => {
    e.preventDefault();
    installPromptState.setValue(e);
  });

  window.addEventListener("appinstalled", () => {
    installPromptState.setValue(undefined);
  });
}

export function useInstallPrompt() {
  const [event, setEvent] = useGlobalState(installPromptState);

  if (!event) {
    return undefined;
  }

  return {
    event: event,
    prompt: async () => {
      event.prompt();

      // Wait for the user to respond to the prompt
      const { outcome } = await event.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      setEvent(undefined);
      return outcome;
    },
    dismiss: () => {
      setEvent(undefined);
    },
  };
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
