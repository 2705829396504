import { CSS, styled } from "@givenwell/stitches";
import { ComponentPropsWithoutRef } from "react";

const Svg = styled("svg", {});

// Logo has 5:6 aspect ratio
export function GivenwellLogo(props: ComponentPropsWithoutRef<"svg"> & { css?: CSS }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="37" height="48" fill="none" {...props} viewBox="40.2 30.2 245 330">
      <path
        d="M110.2,103c-8,0.6-23.4,0.1-27-10.8C79,79.3,96,68.8,101.6,65.4c26.6-16.4,70.3-28.7,90.4-30c6.6-0.4,26.4-2.3,30.6,10.6
	S210,69.5,204.3,72.8C169.2,93.4,131.6,101.4,110.2,103L110.2,103z M155.1,75.7c23.6-7.8,38.8-17.1,45.9-22.6
	c-9-0.2-26.7,1.3-50.3,9.1c-23.6,7.8-38.6,17.1-45.9,22.8C113.5,85.2,131.5,83.6,155.1,75.7L155.1,75.7z"
        fill="currentColor"
      />
      <path
        d="M268.1,154.9c16.9-13.6,19.8-24.3,15.9-29.2c-11.5,2.4-20.3,4.4-27.5,6.3c-12.4-17-32-28.8-61.4-28.8
	c-22.9,0-42.6,9.3-54.5,23.5c-16.5,19.7-19.8,52.9-21.9,62.4c-9.2,41.2-26.6,75-78.5,120.9c70,6.4,128.2-26.3,142.8-98.3
	c1.2-5.8,9.9-4.2,8.9,1.7c-9.3,57.8-45.7,94-94.5,108c6.3,3.1,25.9,9.4,46.4,8.2l1,9.1c1.1,10.2,9.7,17.9,19.9,17.9l2.4-30.4
	c4.7-1.2,9.2-2.6,13.5-4.1l1.8,16.6c1.1,10.2,9.7,17.9,19.9,17.9l3.8-46.6C287.7,258.6,276.6,174,268.1,154.9L268.1,154.9z
	 M209.2,135.6c9,0,12.5,8.6,11.9,12.6c-6,1.2-12.8,4.2-18.4,9.3c-3.2-2.1-5.4-5.8-5.4-9.9C197.3,140.9,202.6,135.6,209.2,135.6z"
        fill="currentColor"
      />
    </Svg>
  );
}
export function GivenwellLogoOnDark(props: ComponentPropsWithoutRef<"svg"> & { css?: CSS }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="37" height="48" fill="none" {...props} viewBox="40.2 30.2 245 330">
      <path
        fill="#fff"
        d="M110.21,103.02c-8.01.59-23.38.11-26.96-10.84-4.23-12.85,12.78-23.35,18.36-26.79,26.64-16.43,70.27-28.73,90.42-29.95,6.57-.4,26.41-2.28,30.64,10.57,4.23,12.85-12.71,23.47-18.36,26.79-35.06,20.58-72.74,28.65-94.09,30.22ZM155.15,75.73c23.64-7.84,38.78-17.06,45.9-22.56-8.98-.17-26.66,1.29-50.33,9.11-23.64,7.84-38.59,17.13-45.87,22.76,8.63.13,26.63-1.49,50.29-9.3Z"
      />
      <path
        fill="#5cc1bf"
        d="M268.06,154.85c16.85-13.55,19.81-24.32,15.88-29.19-11.48,2.42-20.31,4.44-27.49,6.29-12.35-16.97-32-28.81-61.4-28.81-22.89,0-42.65,9.31-54.54,23.5-16.48,19.68-19.75,52.86-21.87,62.44-9.15,41.21-26.58,75.02-78.48,120.95,69.96,6.39,128.22-26.34,142.84-98.26,1.23-5.76,9.95-4.16,8.95,1.73-9.28,57.84-45.72,94.02-94.5,107.99,6.35,3.1,25.92,9.44,46.45,8.21l.98,9.06c1.05,10.2,9.67,17.9,19.91,17.9l2.45-30.41c4.74-1.19,9.24-2.59,13.47-4.13l1.8,16.64c1.05,10.2,9.67,17.9,19.91,17.9l3.75-46.64c81.52-51.41,70.42-136.02,61.88-155.19ZM209.18,135.6c8.97.02,12.53,8.63,11.86,12.59-5.99,1.22-12.79,4.19-18.41,9.29-3.23-2.13-5.37-5.79-5.37-9.95,0-6.59,5.34-11.94,11.92-11.93Z"
      />
    </Svg>
  );
}
export function GivenwellLogoOnLight(props: ComponentPropsWithoutRef<"svg"> & { css?: CSS }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="37" height="48" fill="none" {...props} viewBox="40.2 30.2 245 330">
      <path
        fill="#5cc1bf"
        d="M110.21,103.02c-8.01.59-23.38.11-26.96-10.84-4.23-12.85,12.78-23.35,18.36-26.79,26.64-16.43,70.27-28.73,90.42-29.95,6.57-.4,26.41-2.28,30.64,10.57,4.23,12.85-12.71,23.47-18.36,26.79-35.06,20.58-72.74,28.65-94.09,30.22ZM155.15,75.73c23.64-7.84,38.78-17.06,45.9-22.56-8.98-.17-26.66,1.29-50.33,9.11-23.64,7.84-38.59,17.13-45.87,22.76,8.63.13,26.63-1.49,50.29-9.3Z"
      />
      <path
        fill="#2e2d75"
        d="M268.06,154.85c16.85-13.55,19.81-24.32,15.88-29.19-11.48,2.42-20.31,4.44-27.49,6.29-12.35-16.97-32-28.81-61.4-28.81-22.89,0-42.65,9.31-54.54,23.5-16.48,19.68-19.75,52.86-21.87,62.44-9.15,41.21-26.58,75.02-78.48,120.95,69.96,6.39,128.22-26.34,142.84-98.26,1.23-5.76,9.95-4.16,8.95,1.73-9.28,57.84-45.72,94.02-94.5,107.99,6.35,3.1,25.92,9.44,46.45,8.21l.98,9.06c1.05,10.2,9.67,17.9,19.91,17.9l2.45-30.41c4.74-1.19,9.24-2.59,13.47-4.13l1.8,16.64c1.05,10.2,9.67,17.9,19.91,17.9l3.75-46.64c81.52-51.41,70.42-136.02,61.88-155.19ZM209.18,135.6c8.97.02,12.53,8.63,11.86,12.59-5.99,1.22-12.79,4.19-18.41,9.29-3.23-2.13-5.37-5.79-5.37-9.95,0-6.59,5.34-11.94,11.92-11.93Z"
      />
    </Svg>
  );
}

export function GivenwellDeadBirdLogo(props: ComponentPropsWithoutRef<"svg"> & { css?: CSS }) {
  return (
    <Svg width="60" height="43" viewBox="0 0 60 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9433 36.4528C39.3214 37.15 37.6632 36.9755 35.2583 34.8227L35.261 34.8208C32.6449 36.5441 20.4524 40.2336 10.9007 29.475L3.94015 30.0553C3.69149 28.547 4.61626 27.0904 6.09309 26.6881L8.50029 26.0189C8.17073 25.4332 7.85525 24.8045 7.56491 24.1352L3.02638 24.5128C2.77772 23.0046 3.70249 21.5479 5.17932 21.1456L6.49 20.7813C5.81031 17.7873 6.26889 14.7509 6.57123 13.7403C9.81338 20.5859 16.0272 25.0744 24.7717 25.0368C25.6635 25.041 25.6874 23.7178 24.8092 23.6765C13.8612 23.2696 7.62572 15.4834 6.86798 5.02391C14.8932 11.5529 20.2964 13.2991 26.5883 13.646C26.8202 13.6587 27.1401 13.6612 27.5276 13.6642C29.5838 13.6801 33.5402 13.7107 36.316 15.351C38.6948 16.7577 40.5459 19.442 41.1017 22.8135C41.8156 27.1438 40.5489 30.3255 38.3494 32.5566C38.7961 33.5692 39.3081 34.8207 39.9433 36.4528ZM35.0975 27.7355C35.697 27.738 36.8787 27.0046 36.6639 25.683C36.5056 24.7135 35.5879 24.0569 34.6172 24.217C34.0045 24.318 33.5174 24.722 33.2821 25.2495C34.1697 25.9534 34.7724 26.8828 35.0975 27.7355Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 21.5C40.8494 22.7781 41.9309 24.8028 42.6033 25.7955L42.604 25.7968C44.3958 28.4428 48.244 32.7185 53.516 35.7242C54.3661 36.2084 57.0009 37.6103 58.3491 36.1067C59.6391 34.668 58.1203 32.3832 57.5132 31.47C57.4858 31.4289 57.4603 31.3905 57.437 31.3551C55.7798 28.8383 50.9415 24.1074 46.833 21.8825C45.9727 21.4164 43.3481 19.9964 42 21.5ZM49.4789 29.5805C52.2525 32.0563 54.5722 33.3295 55.8138 33.8417C55.1675 32.6669 53.6631 30.4876 50.8898 28.0064C48.1162 25.5305 45.8015 24.2871 44.5312 23.7639C45.1566 24.8904 46.7056 27.0992 49.4768 29.5799L49.4789 29.5805Z"
        fill="currentColor"
      />
    </Svg>
  );
}

export function GivenwellLogoMark(props: ComponentPropsWithoutRef<"svg"> & { css?: CSS }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="40.2 30.2 1290 330" {...props}>
      <path
        fill="#2e2d75"
        d="M534.79,183.08c9.43,0,16.29,6.86,16.29,15.86s-6.86,16.07-16.29,16.07-16.07-6.86-16.07-16.07,6.64-15.86,16.07-15.86ZM523,333.54v-91.73c0-6.22,5.36-11.14,11.57-11.14s11.36,4.93,11.36,11.14v91.73c0,6.43-5.14,11.36-11.36,11.36s-11.57-4.93-11.57-11.36Z"
      />
      <path
        fill="#2e2d75"
        d="M606.37,337.82l-42.22-90.66c-.64-1.5-1.07-3.21-1.07-5.14,0-6.43,5.36-11.57,11.79-11.57,5.36,0,8.79,3,10.29,6.43l32.15,73.94,31.93-73.73c1.5-3.64,4.93-6.64,10.5-6.64,6.43,0,11.79,5.14,11.79,11.57,0,1.93-.43,3.86-1.07,5.36l-42.44,90.44c-1.72,3.86-5.14,7.07-10.72,7.07-6,0-9.22-3.43-10.93-7.07Z"
      />
      <path
        fill="#2e2d75"
        d="M678.17,287.67c0-33.01,23.79-58.94,58.3-58.94,37.94,0,56.8,29.58,56.8,55.51,0,6.65-3.64,12.65-12.22,12.65h-79.09c3.43,19.07,17.79,28.72,35.36,28.72,11.79,0,21.22-4.5,28.51-11.57,2.14-1.93,4.72-3.21,7.72-3.21,6,0,10.93,4.93,10.93,10.93,0,2.79-1.07,5.36-3,7.29-9.22,9.86-23.58,17.79-44.15,17.79-34.94,0-59.15-25.29-59.15-59.15ZM769.69,277.17c-1.71-18-16.29-27.65-33.22-27.65-18.65,0-30.65,11.79-34.29,27.65h67.51Z"
      />
      <path
        fill="#2e2d75"
        d="M822.19,344.9c-6.22,0-11.14-5.14-11.14-11.36v-91.73c0-6.22,4.93-11.14,11.36-11.14,5.57,0,10.29,4.07,10.93,9.64,7.29-7.29,17.57-11.57,30.22-11.57,31.72,0,48.22,22.29,48.22,48.65v56.15c0,6.21-5.14,11.36-11.36,11.36s-11.14-5.14-11.14-11.36v-54.44c0-16.72-10.72-29.15-27.65-29.15-15.86,0-27.22,10.93-28.08,26.36v57.23c0,6.21-5.14,11.36-11.36,11.36Z"
      />
      <path
        fill="#2e2d75"
        d="M1034.58,337.61l-27.86-68.8-28.08,68.8c-1.5,3.64-4.93,7.29-10.72,7.29s-9.43-3.64-10.72-7.29l-33.65-90.87c-.43-1.29-.86-3-.86-4.5,0-6.64,5.14-11.79,11.79-11.79,5.79,0,9.64,4.07,10.93,7.72l22.93,69.23,27-69.01c1.93-4.5,5.14-7.93,11.36-7.93s9.43,3.43,11.14,7.93l27,69.01,23.15-69.23c1.07-3.64,5.14-7.72,10.93-7.72,6.43,0,11.79,5.14,11.79,11.79,0,1.5-.64,3.21-1.07,4.5l-33.44,90.87c-1.29,3.64-4.93,7.29-10.93,7.29s-9.22-3.64-10.72-7.29Z"
      />
      <path
        fill="#2e2d75"
        d="M1098.01,287.67c0-33.01,23.79-58.94,58.3-58.94,37.94,0,56.8,29.58,56.8,55.51,0,6.65-3.64,12.65-12.22,12.65h-79.09c3.43,19.07,17.79,28.72,35.36,28.72,11.79,0,21.22-4.5,28.51-11.57,2.14-1.93,4.71-3.21,7.71-3.21,6,0,10.93,4.93,10.93,10.93,0,2.79-1.07,5.36-3,7.29-9.22,9.86-23.58,17.79-44.15,17.79-34.93,0-59.15-25.29-59.15-59.15ZM1189.53,277.17c-1.72-18-16.29-27.65-33.22-27.65-18.65,0-30.65,11.79-34.29,27.65h67.51Z"
      />
      <path
        fill="#2e2d75"
        d="M1233.89,319.18v-125.59c0-6.22,4.93-11.14,11.14-11.14s11.36,4.93,11.36,11.14v124.95c0,3.43,1.93,5.14,4.93,5.14,6.21,0,9.86,5.36,9.86,10.07,0,6.43-5.36,11.14-11.57,11.14h-2.36c-14.79,0-23.36-9.86-23.36-25.72Z"
      />
      <path
        fill="#2e2d75"
        d="M1288.33,319.18v-125.59c0-6.22,4.93-11.14,11.14-11.14s11.36,4.93,11.36,11.14v124.95c0,3.43,1.93,5.14,4.93,5.14,6.21,0,9.86,5.36,9.86,10.07,0,6.43-5.36,11.14-11.57,11.14h-2.36c-14.79,0-23.36-9.86-23.36-25.72Z"
      />
      <path
        fill="#2e2d75"
        d="M417.53,346.54c-47.2,0-85.61-38.4-85.61-85.61s38.4-85.61,85.61-85.61c23.48,0,43.83,8.44,60.47,25.09,4.47,4.47,4.47,11.71,0,16.18-4.47,4.47-11.71,4.47-16.18,0-12.37-12.37-26.85-18.38-44.29-18.38-34.59,0-62.72,28.14-62.72,62.72s28.14,62.72,62.72,62.72,62.62-28.02,62.72-51.28h-38.6c-6.32,0-11.44-5.12-11.44-11.44s5.12-11.44,11.44-11.44h38.74c12.55,0,22.75,10.21,22.75,22.75,0,17.94-9.31,36.56-25.54,51.08-16.74,14.98-38.07,23.22-60.07,23.22Z"
      />
      <path
        fill="#5cc1bf"
        d="M109.48,103.02c-8.01.59-23.38.11-26.96-10.84-4.23-12.85,12.78-23.35,18.36-26.79,26.64-16.43,70.27-28.73,90.42-29.95,6.57-.4,26.41-2.28,30.64,10.57,4.23,12.85-12.71,23.47-18.36,26.79-35.06,20.58-72.74,28.65-94.09,30.22ZM154.42,75.73c23.64-7.84,38.78-17.06,45.9-22.56-8.98-.17-26.66,1.29-50.33,9.11-23.64,7.84-38.59,17.13-45.87,22.76,8.63.13,26.63-1.49,50.29-9.3Z"
      />
      <path
        fill="#2e2d75"
        d="M267.33,154.85c16.85-13.55,19.81-24.32,15.88-29.19-11.48,2.42-20.31,4.44-27.49,6.29-12.35-16.97-32-28.81-61.4-28.81-22.89,0-42.65,9.31-54.54,23.5-16.48,19.68-19.75,52.86-21.87,62.44-9.15,41.21-26.58,75.02-78.48,120.95,69.96,6.39,128.22-26.34,142.84-98.26,1.23-5.76,9.95-4.16,8.95,1.73-9.28,57.84-45.72,94.02-94.5,107.99,6.35,3.1,25.92,9.44,46.45,8.21l.98,9.06c1.05,10.2,9.67,17.9,19.91,17.9l2.45-30.41c4.74-1.19,9.24-2.59,13.47-4.13l1.8,16.64c1.05,10.2,9.67,17.9,19.91,17.9l3.75-46.64c81.52-51.41,70.42-136.02,61.88-155.19ZM208.44,135.6c8.97.02,12.53,8.63,11.86,12.59-5.99,1.22-12.79,4.19-18.41,9.29-3.23-2.13-5.37-5.79-5.37-9.95,0-6.59,5.34-11.94,11.92-11.93Z"
      />
    </Svg>
  );
}
