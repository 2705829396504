import { useMeQuery } from "@/api/auth";
import { useMyLocationQuery } from "@/api/me";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { appRoute } from "../+route";

export const settingsRoute = createRoute({
  path: "settings",
  getParentRoute: () => appRoute,
  loader: () => {
    useMeQuery.prefetch();
    useMyLocationQuery.prefetch();
  },
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
