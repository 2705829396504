import { useMeQuery } from "@/api/auth";
import { api, auth } from "@/api/utils";
import { Center, Spinner } from "@givenwell/components";
import {
  createRootRoute,
  createRoute,
  Outlet,
  redirect,
  ScrollRestoration,
  ScrollRestorationOptions,
} from "@tanstack/react-router";
import { Suspense } from "react";

export const rootRoute = createRootRoute({
  component: RootLayout,
});

export const authRoute = createRoute({
  id: "auth",
  getParentRoute: () => rootRoute,
  beforeLoad: async ({ location, cause }) => {
    if (cause !== "enter") {
      return;
    }
    if (auth.hasAccessToken()) {
      return;
    }
    try {
      await auth.refresh(true);
      await api.auth.getMarketplaceUserInfo();
    } catch (e) {
      throw redirect({
        to: "/sign-in",
        search: {
          ...location.search,
          to: location.href,
        },
        replace: true,
      });
    }
  },
  loader: () => {
    useMeQuery.prefetch();
  },
  pendingComponent: Outlet,
});

const getKey: ScrollRestorationOptions["getKey"] = location => {
  return location.pathname;
};

function RootLayout() {
  return (
    <>
      <ScrollRestoration getKey={getKey} />
      <Suspense
        fallback={
          <Center css={{ w: "100%", h: 64 }}>
            <Spinner />
          </Center>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
}
