import { useInfiniteTransactionsQuery } from "@/api/transactions";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { appRoute } from "../+route";

export const walletRoute = createRoute({
  path: "wallet",
  getParentRoute: () => appRoute,
  loader: () => {
    useInfiniteTransactionsQuery.prefetch();
  },
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
