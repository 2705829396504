import { CSS, styled } from "@givenwell/stitches";
import { HTMLAttributes } from "react";

type LayoutProps = HTMLAttributes<HTMLDivElement> & {
  css?: CSS;
};

export type BoxProps = LayoutProps;
export type FlexProps = LayoutProps;
export type GridProps = LayoutProps;
export type CenterProps = LayoutProps;

export const Box = styled("div");
export const Img = styled("img");

export const Flex = styled("div", {
  display: "flex",
});

export const Grid = styled("div", {
  display: "grid",
});

export const Center = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
