import { styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import * as Slider from "@radix-ui/react-slider";

export function RadiusSlider({
  value,
  onChange,
  onCommit,
}: {
  value: number;
  onChange?: (value: number) => void;
  onCommit?: (value: number) => void;
}) {
  return (
    <SliderRoot
      data-vaul-no-drag
      min={2}
      max={150}
      step={2}
      value={[value]}
      onValueChange={([min]) => onChange?.(min!)}
      onValueCommit={([min]) => onCommit?.(min!)}
    >
      <Track data-vaul-no-drag>
        <Range data-vaul-no-drag />
      </Track>
      <Thumb data-vaul-no-drag aria-label="Maximum Distance (km)" />
    </SliderRoot>
  );
}
const SliderRoot = styled(Slider.Root, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  h: 24,
  w: "100%",
});
const Track = styled(Slider.Track, {
  position: "relative",
  backgroundColor: colors.gray100,
  height: 2,
  width: "100%",
  borderRadius: 2,
});
const Range = styled(Slider.Range, {
  position: "absolute",
  backgroundColor: colors.blue800,
  height: "100%",
});
const Thumb = styled(Slider.Thumb, {
  display: "block",
  height: 24,
  width: 24,
  borderRadius: "50%",
  backgroundColor: "white",
  border: `1px solid ${colors.gray100}`,
  boxShadow: "0 3px 6px #16154640",
});
