import { CSS, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { ComponentPropsWithRef, useContext } from "react";
import { FieldContext } from ".";

export type LabelProps = ComponentPropsWithRef<"label"> & { css?: CSS; required?: boolean };

export function Label(props: LabelProps) {
  const { labelProps } = useContext(FieldContext);
  return <Root {...labelProps} {...props} />;
}

const Root = styled("label", {
  d: "block",
  fontScale: "sm",
  weight: 500,
  variants: {
    required: {
      true: {
        "&::after": {
          content: "'*'",
          fontWeight: 400,
          fontScale: "lg",
          lineHeight: 0,
          display: "inline-block",
          transform: "translate(4px, 4px)",
          color: colors.blue600,
        },
      },
    },
  },
});
