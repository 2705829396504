import { useMeQuery } from "@/api/auth";
import { BottomNav, BottomNavItem } from "@/components/ui/BottomNav";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import { IconBuildingStoreFilled, IconMessage2Filled, IconVideoFilled } from "@/icons";
import { Box, Flex } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { IconBuildingStore, IconDots, IconHistory, IconMessage2, IconVideo } from "@tabler/icons-react";
import { createRoute, getRouteApi, Link, Outlet, RegisteredRouter, RouteIds, useMatches } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { lazy, Suspense, useRef, useState } from "react";
import { boolean, fallback, object, optional } from "valibot";
import { authRoute } from "../__root";
import { TopAppBar } from "./TopAppBar";

export const appRoute = createRoute({
  id: "app",
  getParentRoute: () => authRoute,
  loader: () => {
    useMeQuery.prefetch();
  },
  validateSearch: valibotSearchValidator(
    object({
      fullscreen: fallback(optional(boolean()), undefined),
    }),
  ),
  pendingComponent: PendingAppLayout,
  component: AppLayout,
  notFoundComponent: NotFoundComponent,
});

// ------------------------------------------------------------

const NavDrawer = lazy(() => import("./NavDrawer"));
type RouteId = RouteIds<RegisteredRouter["routeTree"]>;

const routesWithMobileNav: RouteId[] = [
  "/auth/app/marketplace/",
  "/auth/app/messages/",
  "/auth/app/purchases/",
  "/auth/app/resources/",
  "/auth/app/favourites",
  "/auth/app/settings",
  "/auth/app/wallet",
];

// evil
const routesWithMobileNavOnSmBreakpoint: RouteId[] = ["/auth/app/messages/$conversationId"];

const route = getRouteApi("/auth/app");

function AppLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const hasUnreads = useHasUnreads();

  const shouldShowMobileNav = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNav.includes(match.routeId));
    },
  });
  const shouldShowMobileNavOnSmBreakpoint = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNavOnSmBreakpoint.includes(match.routeId));
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null!);

  const { fullscreen } = route.useSearch();

  return (
    <Flex css={{ flexDirection: "column", height: "100%" }}>
      {/* Mobile "Top Bar" */}
      <Box
        data-fullscreen={fullscreen ? "" : undefined}
        css={{
          minHeight: "var(--safe-area-inset-top)",
          backgroundColor: "white",
          zIndex: 1000,
          "@lg": {
            display: "none",
          },
          "&[data-fullscreen]": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
          },
        }}
      />
      {/* Desktop Top bar */}
      <TopAppBar scrollContainerRef={scrollContainerRef} />
      <Flex
        ref={scrollContainerRef}
        css={{
          flexDir: "column",
          flex: "1 0 0px",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Flex>

      <BottomNav
        css={{
          display: shouldShowMobileNav ? "flex" : "none",
          "@sm": {
            display: shouldShowMobileNavOnSmBreakpoint ? "flex" : undefined,
          },
        }}
      >
        <BottomNavItem
          label="Marketplace"
          icon={<IconBuildingStore />}
          activeIcon={<IconBuildingStoreFilled />}
          to="/marketplace"
        />
        <BottomNavItem
          label="Messages"
          icon={<IconMessage2 />}
          activeIcon={<IconMessage2Filled />}
          to="/messages"
          badge={
            hasUnreads ?
              <Box
                css={{
                  position: "absolute",
                  top: -3,
                  right: -3,
                  rounded: 10,
                  bg: colors.blue800,
                  boxShadow: "0 0 0 2px #fff",
                  minW: "10px",
                  h: "10px",
                }}
              />
            : null
          }
        />
        <BottomNavItem label="Purchases" icon={<IconHistory />} to="/purchases" />
        <BottomNavItem label="Resources" icon={<IconVideo />} activeIcon={<IconVideoFilled />} to="/resources" />
        <Suspense fallback={<BottomNavItem label="More" icon={<IconDots />} to="" />}>
          <BottomNavItem onClick={() => setDrawerOpen(true)} label="More" icon={<IconDots />} to="" />
          <NavDrawer open={drawerOpen} onOpenChange={setDrawerOpen} />
        </Suspense>
      </BottomNav>
    </Flex>
  );
}

function PendingAppLayout() {
  const shouldShowMobileNav = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNav.includes(match.routeId));
    },
  });
  const shouldShowMobileNavOnSmBreakpoint = useMatches({
    select(matches) {
      return matches.some(match => routesWithMobileNavOnSmBreakpoint.includes(match.routeId));
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null!);

  const { fullscreen } = route.useSearch();

  return (
    <Flex css={{ flexDirection: "column", height: "100%" }}>
      {/* Mobile "Top Bar" */}
      <Box
        data-fullscreen={fullscreen ? "" : undefined}
        css={{
          minHeight: "var(--safe-area-inset-top)",
          backgroundColor: "white",
          zIndex: 1000,
          "@lg": {
            display: "none",
          },
          "&[data-fullscreen]": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
          },
        }}
      />
      {/* Desktop Top bar */}
      <TopAppBar scrollContainerRef={scrollContainerRef} />
      <Flex
        ref={scrollContainerRef}
        css={{
          flexDir: "column",
          flex: "1 0 0px",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Flex>

      <BottomNav
        css={{
          display: shouldShowMobileNav ? "flex" : "none",
          "@sm": {
            display: shouldShowMobileNavOnSmBreakpoint ? "flex" : undefined,
          },
        }}
      >
        <BottomNavItem
          label="Marketplace"
          icon={<IconBuildingStore />}
          activeIcon={<IconBuildingStoreFilled />}
          to="/marketplace"
        />
        <BottomNavItem label="Messages" icon={<IconMessage2 />} activeIcon={<IconMessage2Filled />} to="/messages" />
        <BottomNavItem label="Purchases" icon={<IconHistory />} to="/purchases" />
        <BottomNavItem label="Resources" icon={<IconVideo />} activeIcon={<IconVideoFilled />} to="/resources" />
        <BottomNavItem label="More" icon={<IconDots />} to="" />
      </BottomNav>
    </Flex>
  );
}

function NotFoundComponent() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
