import { createGlobalState } from "@givenwell/hooks";
import { ReactNode } from "react";

export type Toast = {
  id: string;
  title: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
  type: "log" | "success" | "error";
};

export const toastsState = createGlobalState([] as Toast[]);

function toast(title: ReactNode, opts?: Partial<Toast>) {
  const id = Math.random().toString(36);
  const toast: Toast = { id, title, type: "log", ...opts };

  toastsState.setValue([...toastsState.getValue(), toast]);
  return id;
}
toast.success = (title: ReactNode, opts?: Partial<Toast>) => toast(title, { ...opts, type: "success" });
toast.error = (title: ReactNode, opts?: Partial<Toast>) => toast(title, { ...opts, type: "error" });

export { toast };
