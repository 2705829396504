import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { authRoute } from "../__root";

export const mergeAccountRoute = createRoute({
  path: "merge-account",
  getParentRoute: () => authRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotSearchValidator(
    object({
      token: fallback(optional(string(), ""), ""),
    }),
  ),
});
