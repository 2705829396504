import { useInfinitePurchaseHistoryQuery } from "@/api/purchases";
import { createRoute } from "@tanstack/react-router";
import { appRoute } from "../+route";

export const purchasesRoute = createRoute({
  path: "purchases",
  getParentRoute: () => appRoute,
  loader: () => {
    useInfinitePurchaseHistoryQuery.prefetch();
  },
});
