import { Spinner } from "@givenwell/components";
import { CSS, VariantProps, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { ButtonHTMLAttributes, ForwardedRef, ReactNode, forwardRef } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof Root> & {
    // Require a variant to be provided, just to be explicit.
    variant: NonNullable<VariantProps<typeof Root>["variant"]>;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    loading?: boolean;
    loadingText?: ReactNode;
    css?: CSS;
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  };

/**
 * A button component.
 *
 * Variants: `plain` | `outlined` | `tonal` | `filled`
 *
 * Colors: `primary` | `neutral` | `green` | `red`
 *
 * Sizes: `xs` | `sm` | `md` | `lg`
 */
export const Button = forwardRef(function Button(
  { children, loading, leftIcon, rightIcon, loadingText, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Root type="button" {...props} ref={ref}>
      {(leftIcon || loading) && <Icon>{leftIcon || <Spinner css={{ size: 16 }} />}</Icon>}
      <div>{loading ? loadingText || children : children}</div>
      {rightIcon && <Icon>{rightIcon}</Icon>}
    </Root>
  );
});

const Root = styled("button", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 500,
  transition: "all 0.15s ease-in-out",
  cursor: "pointer",
  userSelect: "none",
  border: "1px solid transparent",
  flexShrink: "0",

  "&:focus-visible": {
    outline: "none",
    boxShadow: `0 0 0 2px white, 0 0 0 4px ${colors.blue500}`,
  },
  "&:active": {
    transitionDuration: "0.05s",
  },

  variants: {
    color: {
      primary: {
        "--button-fill": colors.blue500,
        "--button-fill2": colors.blue600,
        "--button-fill3": colors.blue700,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.blue100,
        "--button-tonal2": colors.blue200,
        "--button-tonal3": colors.blue300,
        "--button-on-tonal": colors.blue600,
        "--button-on-tonal2": colors.blue600,
        "--button-on-tonal3": colors.blue700,

        "--button-outline": colors.blue500,
        "--button-outline2": colors.blue600,
        "--button-outline3": colors.blue700,

        "--button-text": colors.blue500,
        "--button-text2": colors.blue600,
        "--button-text3": colors.blue700,
      },
      neutral: {
        "--button-fill": colors.gray500,
        "--button-fill2": colors.gray600,
        "--button-fill3": colors.gray700,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.gray0,
        "--button-tonal2": colors.gray100,
        "--button-tonal3": colors.gray200,
        "--button-on-tonal": colors.gray900,
        "--button-on-tonal2": colors.gray900,
        "--button-on-tonal3": colors.gray900,

        "--button-outline": colors.gray500,
        "--button-outline2": colors.gray600,
        "--button-outline3": colors.gray700,

        "--button-text": colors.gray900,
        "--button-text2": colors.gray900,
        "--button-text3": colors.gray900,
      },
      green: {
        "--button-fill": colors.green400,
        "--button-fill2": colors.green500,
        "--button-fill3": "#007400",
        "--button-on-fill": colors.white,

        "--button-tonal": colors.green100,
        "--button-tonal2": colors.green200,
        "--button-tonal3": colors.green300,
        "--button-on-tonal": colors.green500,
        "--button-on-tonal2": colors.green600,
        "--button-on-tonal3": colors.green700,

        "--button-outline": colors.green400,
        "--button-outline2": colors.green500,
        "--button-outline3": colors.green600,

        "--button-text": colors.green400,
        "--button-text2": colors.green500,
        "--button-text3": colors.green700,
      },
      red: {
        "--button-fill": colors.red400,
        "--button-fill2": colors.red500,
        "--button-fill3": colors.red600,
        "--button-on-fill": colors.white,

        "--button-tonal": colors.red100,
        "--button-tonal2": colors.red200,
        "--button-tonal3": colors.red300,
        "--button-on-tonal": colors.red500,
        "--button-on-tonal2": colors.red600,
        "--button-on-tonal3": colors.red700,

        "--button-outline": colors.red400,
        "--button-outline2": colors.red500,
        "--button-outline3": colors.red600,

        "--button-text": colors.red400,
        "--button-text2": colors.red500,
        "--button-text3": colors.red700,
      },
    },
    variant: {
      plain: {
        backgroundColor: "transparent",
        color: "var(--button-text)",

        "&:hover": {
          color: "var(--button-on-tonal2)",
        },
        "&:active": {
          color: "var(--button-on-tonal3)",
        },
      },
      outlined: {
        backgroundColor: "transparent",
        color: "var(--button-text)",
        borderColor: "var(--button-outline)",

        "&:hover": {
          color: "var(--button-text2)",
          borderColor: "var(--button-outline2)",
        },
        "&:active": {
          color: "var(--button-text3)",
          borderColor: "var(--button-outline3)",
        },
      },
      tonal: {
        backgroundColor: "var(--button-tonal)",
        color: "var(--button-on-tonal)",

        "&:hover": {
          backgroundColor: "var(--button-tonal2)",
          color: "var(--button-on-tonal2)",
        },
        "&:active": {
          backgroundColor: "var(--button-tonal3)",
          color: "var(--button-on-tonal3)",
        },
      },
      filled: {
        backgroundColor: "var(--button-fill)",
        color: "var(--button-on-fill)",

        "&:hover": {
          backgroundColor: "var(--button-fill2)",
        },
        "&:active": {
          backgroundColor: "var(--button-fill3)",
        },
      },
    },
    size: {
      xs: {
        height: "24px",
        padding: "0 8px",
        gap: "4px",
        borderRadius: "2px",
        fontSize: 12,
      },
      sm: {
        height: "32px",
        padding: "0 12px",
        gap: "8px",
        borderRadius: "4px",
        fontSize: 14,
      },
      md: {
        height: "40px",
        padding: "0 16px",
        gap: "8px",
        borderRadius: "8px",
        fontSize: 16,
        borderWidth: 2,
      },
      lg: {
        height: "48px",
        padding: "0 16px",
        gap: "8px",
        borderRadius: "16px",
        fontSize: 16,
        borderWidth: 2,
      },
    },
  },
  defaultVariants: {
    variant: "plain",
    color: "primary",
    size: "md",
  },

  // Disabled color override
  // "&[disabled]": {
  //   "--button-fill": colors.gray400,
  //   "--button-fill2": colors.gray400,
  //   "--button-fill3": colors.gray400,
  //   "--button-on-fill": colors.white,

  //   "--button-tonal": colors.gray100,
  //   "--button-tonal2": colors.gray100,
  //   "--button-tonal3": colors.gray100,
  //   "--button-on-tonal": colors.gray500,
  //   "--button-on-tonal2": colors.gray500,
  //   "--button-on-tonal3": colors.gray500,

  //   "--button-outline": colors.gray500,
  //   "--button-outline2": colors.gray500,
  //   "--button-outline3": colors.gray500,

  //   "--button-text": colors.gray500,
  //   "--button-text2": colors.gray500,
  //   "--button-text3": colors.gray500,

  //   cursor: "default",
  // },
  "&[disabled]": {
    "--button-fill": colors.gray100,
    "--button-fill2": colors.gray100,
    "--button-fill3": colors.gray100,
    "--button-on-fill": colors.gray500,

    "--button-tonal": colors.gray100,
    "--button-tonal2": colors.gray100,
    "--button-tonal3": colors.gray100,
    "--button-on-tonal": colors.gray500,
    "--button-on-tonal2": colors.gray500,
    "--button-on-tonal3": colors.gray500,

    "--button-outline": colors.transparent,
    "--button-outline2": colors.transparent,
    "--button-outline3": colors.transparent,

    "--button-text": colors.gray500,
    "--button-text2": colors.gray500,
    "--button-text3": colors.gray500,

    cursor: "default",
  },
});

const Icon = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
