import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { rootRoute } from "../__root";

export const demoRoute = createRoute({
  path: "demo",
  getParentRoute: () => rootRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotSearchValidator(
    object({
      path: fallback(optional(string(), "/"), "/"),
      device: fallback(optional(string(), "iphone-15-pro-max"), "iphone-15-pro-max"),
    }),
  ),
});
