import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { marketplaceRoute } from "../+route";

export const purchaseRoute = createRoute({
  path: "purchase/$listingId/$variantId",
  getParentRoute: () => marketplaceRoute,
});

export const purchaseIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => purchaseRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
});
