/**
 * teeny numeric hash function, stolen from java i think
 */
export function hash(str: string) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

const seed: number = (Math.random() * 2 ** 32) >>> 0;
export function makeSeededRandomiser(): () => number {
  let a = seed;
  return function () {
    a |= 0;
    a = (a + 0x9e3779b9) | 0;
    let t = a ^ (a >>> 16);
    t = Math.imul(t, 0x21f0aaad);
    t = t ^ (t >>> 15);
    t = Math.imul(t, 0x735a2d97);
    return ((t = t ^ (t >>> 15)) >>> 0) / 4294967296;
  };
}

// Fisher-Yates shuffle with a seeded RNG.
export function seededShuffle<T>(arr: T[]): T[] {
  const random = makeSeededRandomiser();
  let i = arr.length;
  let j: number;
  let temp: T;
  while (--i > 0) {
    j = Math.floor(random() * (i + 1));
    temp = arr[j]!;
    arr[j] = arr[i]!;
    arr[i] = temp;
  }
  return arr;
}
