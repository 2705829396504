import { useSyncExternalStore } from "react";

export type GlobalState<T> = {
  getValue: () => T;
  setValue: (newValue: T) => void;
  subscribe: (onStoreChange: () => void) => () => void;
};

export function createGlobalState<T>(initialValue: T): GlobalState<T> {
  let value = initialValue;
  const listeners = new Set<() => void>();

  const state: GlobalState<T> = {
    getValue: () => value,
    setValue: newValue => {
      if (Object.is(value, newValue)) return;
      value = newValue;
      listeners.forEach(listener => listener());
    },
    subscribe: onStoreChange => {
      listeners.add(onStoreChange);
      return () => {
        listeners.delete(onStoreChange);
      };
    },
  };

  return state;
}

export function useGlobalState<T>(state: GlobalState<T>) {
  const value = useSyncExternalStore(state.subscribe, state.getValue, state.getValue);

  return [value, state.setValue] as const;
}
