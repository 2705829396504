import { useSupplierListingsQuery, useSupplierQuery } from "@/api/suppliers";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { marketplaceRoute } from "../../+route";

export const supplierRoute = createRoute({
  path: "suppliers/$supplierId",
  getParentRoute: () => marketplaceRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  loader: ({ params }) => {
    useSupplierQuery.prefetch(params.supplierId);
    useSupplierListingsQuery.prefetch(params.supplierId);
  },
});
