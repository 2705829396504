import type { CSS as BaseCSS, CSSProperties, ComponentProps, VariantProps } from "@stitches/react";
import { createStitches } from "@stitches/react";

/**
 * Currently taken from Chakra
 */
const FONT_SCALE = {
  "3xs": [10],
  "2xs": [11],
  xs: [12, 16],
  sm: [14],
  md: [16],
  lg: [18, 28],
  xl: [20],
  "2xl": [24],
  "3xl": [30],
  "4xl": [36],
  "5xl": [48, 48],
  "6xl": [60, 60],
  "7xl": [72, 72],
  "8xl": [96, 96],
  "9xl": [128, 128],
} satisfies Record<string, [fontSizePx: number, lineHeightPx?: number]>;

const TRANSITION_PROPERTIES = {
  none: "none",
  all: "all",
  common:
    "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  colors: "color, background-color, border-color, text-decoration-color, fill, stroke",
  opacity: "opacity",
  shadow: "box-shadow",
  transform: "transform",
};

const SHADOWS = {
  sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  base: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "2xl": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
  inner: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
  none: "none",
};
const DROP_SHADOWS = {
  sm: "drop-shadow(0 1px 2px rgb(0 0 0 / 0.05))",
  base: "drop-shadow(0 1px 3px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))",
  md: "drop-shadow(0 4px 6px rgb(0 0 0 / 0.1)) drop-shadow(0 2px 4px rgb(0 0 0 / 0.1))",
  lg: "drop-shadow(0 10px 15px rgb(0 0 0 / 0.1)) drop-shadow(0 4px 6px rgb(0 0 0 / 0.1))",
  xl: "drop-shadow(0 20px 25px rgb(0 0 0 / 0.1)) drop-shadow(0 8px 10px rgb(0 0 0 / 0.1))",
  "2xl": "drop-shadow(0 25px 50px rgb(0 0 0 / 0.25))",
  none: "none",
};

/**
 * Dynamically generate letter spacing based on font size. Might use it later.
 *
 * From https://rsms.me/inter/dynmetrics
 */
function getLetterSpacing(fontSize: number) {
  const a = -0.0223;
  const b = 0.185;
  const c = -0.1745;

  return a + b * Math.E ** (c * fontSize);
}

const round = (num: number) =>
  num
    .toFixed(7)
    .replace(/(\.[0-9]+?)0+$/, "$1")
    .replace(/\.0$/, "");
export const rem = (px: number) => `${round(px / 16)}rem`;
export const em = (px: number, base: number) => `${round(px / base)}em`;

const { config, css, globalCss, keyframes, styled, getCssText } = createStitches({
  media: {
    // These breakpoints are based on android window size classes
    // https://developer.android.com/guide/topics/large-screens/support-different-screen-sizes
    // https://m3.material.io/foundations/layout/applying-layout/window-size-classes
    xs: "(max-width: 319.98px)", // Literally only here for jonnys galaxy fold
    sm: "(min-width: 600px)", // 99.96% of phones in portrait
    md: "(min-width: 840px)", // 93.73% of tablets in portrait
    lg: "(min-width: 1200px)", // 97.22% of tablets in landscape
    xl: "(min-width: 1600px)", // desktops
    print: "print",
    motionSafe: "(prefers-reduced-motion: no-preference)",
    contrastMore: "(prefers-contrast: more)",
    contrastLess: "(prefers-contrast: less)",
    maxSm: "(max-width: 599.98px)",
    maxMd: "(max-width: 839.98px)",
    maxLg: "(max-width: 1199.98px)",
    maxXl: "(max-width: 1599.98px)",
  },
  utils: {
    // LAYOUT
    d: (value: CSSProperties["display"]) => ({
      display: value,
    }),
    pos: (value: CSSProperties["position"]) => ({
      position: value,
    }),
    inset: (value: CSSProperties["top"]) => ({
      top: value,
      right: value,
      bottom: value,
      left: value,
    }),
    insetX: (value: CSSProperties["left"]) => ({
      left: value,
      right: value,
    }),
    insetY: (value: CSSProperties["top"]) => ({
      top: value,
      bottom: value,
    }),
    // FLEXBOX & GRID
    flexDir: (value: CSSProperties["flexDirection"]) => ({
      flexDirection: value,
    }),
    gridCols: (value: number | CSSProperties["gridTemplateColumns"]) => ({
      gridTemplateColumns: typeof value === "number" ? `repeat(${value}, minmax(0, 1fr))` : value,
    }),
    colSpan: (value: number) => ({
      gridColumn: `span ${value} / span ${value}`,
    }),
    gridRows: (value: CSSProperties["gridTemplateRows"]) => ({
      gridTemplateRows: typeof value === "number" ? `repeat(${value}, minmax(0, 1fr))` : value,
    }),
    rowSpan: (value: number) => ({
      gridRow: `span ${value} / span ${value}`,
    }),
    justify: (value: CSSProperties["justifyContent"]) => ({
      justifyContent: value,
    }),
    items: (value: CSSProperties["alignItems"]) => ({
      alignItems: value,
    }),
    // SPACING
    p: (value: CSSProperties["padding"]) => ({
      padding: value,
    }),
    px: (value: CSSProperties["paddingLeft"]) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: CSSProperties["paddingTop"]) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    pt: (value: CSSProperties["paddingTop"]) => ({
      paddingTop: value,
    }),
    pr: (value: CSSProperties["paddingRight"]) => ({
      paddingRight: value,
    }),
    pb: (value: CSSProperties["paddingBottom"]) => ({
      paddingBottom: value,
    }),
    pl: (value: CSSProperties["paddingLeft"]) => ({
      paddingLeft: value,
    }),
    m: (value: CSSProperties["margin"]) => ({
      margin: value,
    }),
    mx: (value: CSSProperties["marginLeft"]) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: CSSProperties["marginTop"]) => ({
      marginTop: value,
      marginBottom: value,
    }),
    mt: (value: CSSProperties["marginTop"]) => ({
      marginTop: value,
    }),
    mr: (value: CSSProperties["marginRight"]) => ({
      marginRight: value,
    }),
    mb: (value: CSSProperties["marginBottom"]) => ({
      marginBottom: value,
    }),
    ml: (value: CSSProperties["marginLeft"]) => ({
      marginLeft: value,
    }),
    spaceX: (value: CSSProperties["marginLeft"]) => ({
      "> * + *": {
        marginLeft: value,
      },
    }),
    spaceY: (value: CSSProperties["marginTop"]) => ({
      "> * + *": {
        marginTop: value,
      },
    }),
    // SIZING
    w: (value: CSSProperties["width"]) => ({
      width: value,
    }),
    minW: (value: CSSProperties["minWidth"]) => ({
      minWidth: value,
    }),
    maxW: (value: CSSProperties["maxWidth"]) => ({
      maxWidth: value,
    }),
    h: (value: CSSProperties["height"]) => ({
      height: value,
    }),
    minH: (value: CSSProperties["minHeight"]) => ({
      minHeight: value,
    }),
    maxH: (value: CSSProperties["maxHeight"]) => ({
      maxHeight: value,
    }),
    size: (value: CSSProperties["width"]) => ({
      width: value,
      height: value,
    }),
    minSize: (value: CSSProperties["minWidth"]) => ({
      minWidth: value,
      minHeight: value,
    }),
    maxSize: (value: CSSProperties["maxWidth"]) => ({
      maxWidth: value,
      maxHeight: value,
    }),
    // TYPOGRAPHY
    fontSize: (fontSize: CSSProperties["fontSize"]) =>
      typeof fontSize === "number" ?
        {
          fontSize: rem(fontSize),
          // letterSpacing: `${getLetterSpacing(fontSize)}em`,
        }
      : { fontSize },
    fontScale: (scale: keyof typeof FONT_SCALE) => {
      const [fontSize, lineHeight] = FONT_SCALE[scale] || FONT_SCALE["md"];
      if (lineHeight) {
        return {
          fontSize: rem(fontSize),
          lineHeight: em(lineHeight, fontSize),
          // letterSpacing: `${getLetterSpacing(fontSize)}em`,
        };
      }
      return {
        fontSize: rem(fontSize),
        // letterSpacing: `${getLetterSpacing(fontSize)}em`,
      };
    },
    lineClamp: (lineClamp: CSSProperties["lineClamp"]) => ({
      "-webkit-line-clamp": lineClamp,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
    }),
    align: (value: CSSProperties["textAlign"]) => ({
      textAlign: value,
    }),
    weight: (value: CSSProperties["fontWeight"]) => ({
      fontWeight: value,
    }),
    // BACKGROUNDS
    bg: (value: CSSProperties["background"]) => ({
      background: value,
    }),
    bgImage: (value: CSSProperties["backgroundImage"]) => ({
      backgroundImage: value,
    }),
    // BORDERS
    rounded: (value: CSSProperties["borderRadius"]) => ({
      borderRadius: value,
    }),
    roundedT: (value: CSSProperties["borderTopLeftRadius"]) => ({
      borderTopLeftRadius: value,
      borderTopRightRadius: value,
    }),
    roundedR: (value: CSSProperties["borderTopRightRadius"]) => ({
      borderTopRightRadius: value,
      borderBottomRightRadius: value,
    }),
    roundedB: (value: CSSProperties["borderBottomLeftRadius"]) => ({
      borderBottomLeftRadius: value,
      borderBottomRightRadius: value,
    }),
    roundedL: (value: CSSProperties["borderTopLeftRadius"]) => ({
      borderTopLeftRadius: value,
      borderBottomLeftRadius: value,
    }),
    roundedTL: (value: CSSProperties["borderTopLeftRadius"]) => ({
      borderTopLeftRadius: value,
    }),
    roundedTR: (value: CSSProperties["borderTopRightRadius"]) => ({
      borderTopRightRadius: value,
    }),
    roundedBR: (value: CSSProperties["borderBottomRightRadius"]) => ({
      borderBottomRightRadius: value,
    }),
    roundedBL: (value: CSSProperties["borderBottomLeftRadius"]) => ({
      borderBottomLeftRadius: value,
    }),
    // EFFECTS
    shadow: (value: keyof typeof SHADOWS) => ({
      boxShadow: SHADOWS[value],
    }),
    dropShadow: (value: keyof typeof DROP_SHADOWS) => ({
      filter: DROP_SHADOWS[value],
    }),
    // TRANSITIONS & ANIMATIONS
    transitionType: (value: keyof typeof TRANSITION_PROPERTIES) => ({
      transitionProperty: TRANSITION_PROPERTIES[value],
    }),
    easing: (value: CSSProperties["transitionTimingFunction"]) => ({
      transitionTimingFunction: value,
    }),
    duration: (value: CSSProperties["transitionDuration"]) => ({
      transitionDuration: value,
    }),
    // ACCESSIBILITY
    srOnly: (value: boolean) => {
      return value ?
          {
            position: "absolute",
            width: 1,
            height: 1,
            padding: 0,
            margin: -1,
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: 0,
          }
        : {
            position: "static",
            width: "auto",
            height: "auto",
            padding: 0,
            margin: 0,
            overflow: "visible",
            clip: "auto",
            whiteSpace: "normal",
          };
    },
  },
});

type CSS = BaseCSS<typeof config>;

export { config, css, getCssText, globalCss, keyframes, styled };
export type { CSS, ComponentProps, VariantProps };
