import { CSS, VariantProps, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { ComponentPropsWithRef, ForwardedRef, ReactNode, forwardRef, useContext } from "react";
import { FieldContext } from ".";

export type InputProps = Omit<ComponentPropsWithRef<"input">, "size"> & {
  size?: VariantProps<typeof Root>["size"];
  css?: CSS;
  invalid?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onValueChange?: (value: string) => void;
  onValueCommit?: (value: string) => void;
};

export const Input = forwardRef(function Input(
  { leftIcon, rightIcon, size, invalid, onValueChange, onValueCommit, css, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { inputProps } = useContext(FieldContext);
  return (
    <Root size={size} css={css}>
      <StyledInput
        {...inputProps}
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
        invalid={invalid}
        {...props}
        ref={ref}
        onChange={e => {
          props.onChange?.(e);
          onValueChange?.(e.target.value);
        }}
        onBlur={e => {
          props.onBlur?.(e);
          if (!e.defaultPrevented) {
            onValueCommit?.(e.target.value);
          }
        }}
        onKeyDown={e => {
          props.onKeyDown?.(e);
          if (!e.defaultPrevented) {
            if (e.key === "Enter") {
              const target = e.target;
              if (target instanceof HTMLInputElement) {
                onValueCommit?.(target.value);
              }
            }
          }
        }}
      />
      {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
      {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
    </Root>
  );
});

const Root = styled("div", {
  pos: "relative",
  w: "100%",
  variants: {
    size: {
      xs: {
        "--height": "24px",
        "--padding": "8px",
        "--rounded": "2px",
        fontScale: "xs",
      },
      sm: {
        "--height": "32px",
        "--padding": "12px",
        "--rounded": "4px",
        fontScale: "sm",
      },
      md: {
        "--height": "40px",
        "--padding": "16px",
        "--rounded": "8px",
        fontScale: "md",
      },
      lg: {
        "--height": "48px",
        "--padding": "16px",
        "--rounded": "8px",
        fontScale: "lg",
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});
const StyledInput = styled("input", {
  minW: 0,
  w: "100%",
  height: "var(--height)",
  rounded: "var(--rounded)",
  px: "var(--padding)",
  bg: "white",
  border: `1px solid ${colors.gray200}`,
  "&::placeholder": {
    color: colors.gray400,
  },
  textAlign: "inherit",

  caretColor: colors.blue500,
  "&:focus": {
    outline: "none",
    borderColor: colors.blue500,
    boxShadow: `0 0 0 1px ${colors.blue500}`,
  },

  variants: {
    hasLeftIcon: {
      true: {
        pl: "var(--height)",
      },
    },
    hasRightIcon: {
      true: {
        pr: "var(--height)",
      },
    },

    invalid: {
      true: {
        caretColor: colors.red400,
        borderColor: colors.red400,
        boxShadow: `0 0 0 1px ${colors.red400}`,
        "&:focus": {
          outline: "none",
          borderColor: colors.red400,
          boxShadow: `0 0 0 1px ${colors.red400}`,
        },
      },
    },
  },
});
const LeftIcon = styled("div", {
  pos: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
  width: "var(--height)",
  color: colors.gray300,
});
const RightIcon = styled("div", {
  pos: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
  width: "var(--height)",
  color: colors.gray300,
});
