import { CSS, keyframes, styled } from "@givenwell/stitches";
import { DetailedHTMLProps, ForwardedRef, forwardRef } from "react";

export type SpinnerProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  css?: CSS;
};

export const Spinner = forwardRef(function Spinner(props: SpinnerProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <Root {...props} ref={ref}>
      <div />
      <Label>Loading...</Label>
    </Root>
  );
});

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const Root = styled("div", {
  display: "inline-block",
  borderColor: "currentColor",
  borderStyle: "solid",
  borderRadius: 9999,
  borderWidth: 2,
  borderBottomColor: "transparent",
  borderLeftColor: "transparent",
  size: "1.5rem",
  animation: `${spin()} 0.45s linear infinite`,
});

const Label = styled("span", {
  srOnly: true,
});
