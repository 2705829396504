import { notFound } from "@tanstack/react-router";

const IDS = {
  GIVENWELL: "givw_",
  SUPPORTER: "supp_",
  SUPPLIER: "sppl_",
  VARIANT: "var_",
  PURCHASE: "pur_",
  ENTRY: "entr_",
  LISTING: "lst_",
  MEDIA: "med_",
  MEDIA_SET: "mset_",
  SKU: "sku_",
  LINE_ITEM: "li_",
  SUPPORTER_MEMBER: "smem_",
  SUPPORTER_MEMBER_WALLET: "smwl_",
  SUPPORTER_MEMBER_INVITATION: "smi_",
  TIME_SPAN_ID: "tspn_",
  CALENDAR_ID: "cal_",
  LOCATION_ID: "loc_",
  CATEGORY_ID: "cat_",
  REGION: "reg_",
  AUDIT_EVENT: "aud_",
  CANCELLATION_POLICY_ID: "cclp_",
  CANCELLATION_POLICY_ENTRY_ID: "ccle_",
  RESOURCE: "res_",
  PAYOUT: "pout_",
  PAYIN: "pin_",
  CONVERSATION: "conv_",
  CONVERSATION_MESSAGE: "cmsg_",
  CONVERSATION_ATTACHMENT: "catt_",
  AUTO_MESSAGE: "amsg_",
  AUTO_MESSAGE_ASSOCIATION: "ama_",
  AUTO_MESSAGE_ATTACHMENT: "amat_",
  ALLOWANCE: "all_",
  ALLOWANCE_RULE: "alr_",
  ALLOWANCE_BATCH: "alb_",
  ALLOWANCE_BATCH_TOKEN_CLAIM: "abtc_",
  PAYIN_INVOICE: "pinv_",
  TOKEN_EXPIRATION_ID: "tex_",
  SUBSCRIPTION_ID: "sub_",
  REQUESTED_SUPPLIER: "reqs_",
  SUPPLIER_SELF_SIGN_UP: "sssu_",
  PAYIN_SURPLUS: "surp_",
  MANAGEMENT_INVITATION: "mgiv_",
  EAP_USAGE: "eapu_",
};

export function checkIdPrefix(id: string, type: keyof typeof IDS) {
  if (!id.startsWith(IDS[type])) {
    throw notFound();
  }
}
