import { api, auth, queryClient } from "@/api/utils";
import { createRoute, redirect } from "@tanstack/react-router";
import { rootRoute } from "./__root";

export const indexRoute = createRoute({
  path: "/",
  getParentRoute: () => rootRoute,
  beforeLoad: async () => {
    if (auth.hasAccessToken()) {
      queryClient.clear();
      throw redirect({
        to: "/marketplace",
        replace: true,
      });
    }
    try {
      await auth.refresh(true);
      await api.auth.getMarketplaceUserInfo();
    } catch (e) {
      throw redirect({
        to: "/sign-in",
        replace: true,
      });
    }
    throw redirect({
      to: "/marketplace",
      replace: true,
    });
  },
});
