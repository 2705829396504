import {
  QueryObserverLoadingErrorResult,
  QueryObserverPendingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { Center, Heading, Spinner } from "..";

type JSXElementOrNull = JSX.Element | null;

// Not sure why this is needed, it didn't used to be needed, but it is now.
//
// Conveniently, undefined is a disallowed value for `data` anyway, so this is
// safe, and will lint any queries that return undefined when they shouldn't.
type NonUndefined<T> = T extends undefined ? never : T;

type ErrorResult<TData, TError> =
  | QueryObserverLoadingErrorResult<TData, TError>
  | QueryObserverRefetchErrorResult<TData, TError>;

interface CreateQueryHandlerOptions<TError> {
  /**
   * Default error handler
   */
  error: (query: ErrorResult<unknown, TError>) => JSXElementOrNull;
  /**
   * Default pending handler
   */
  pending: (query: QueryObserverPendingResult<unknown, TError>) => JSXElementOrNull;
}

interface QueryHandlerOptions<TData, TError> {
  query: UseQueryResult<TData, TError>;
  /**
   * Optionally override default error handling
   */
  error?: (query: ErrorResult<TData, TError>) => JSXElementOrNull;
  /**
   * Optionally override pending state
   */
  pending?: (query: QueryObserverPendingResult<TData, TError>) => JSXElementOrNull;
}

interface QueryHandlerOptionsWithEmpty<TData, TError> extends QueryHandlerOptions<TData, TError> {
  /**
   * Render callback for when the data is fetched successfully
   */
  success: (query: QueryObserverSuccessResult<NonNullable<TData>, TError>) => JSXElementOrNull;
  /**
   * Render callback for when the data is empty - when the `data` is `null`-ish or an empty array
   */
  empty: (query: QueryObserverSuccessResult<TData, TError>) => JSXElementOrNull;
}
interface QueryHandlerOptionsNoEmpty<TData, TError> extends QueryHandlerOptions<TData, TError> {
  success: (query: QueryObserverSuccessResult<TData, TError>) => JSXElementOrNull;
}

// Export the factory so we can have different defaults in different envs/contexts
export function createQueryHandler<TError = Error>(queryCellOpts: CreateQueryHandlerOptions<TError>) {
  function QueryHandler<TData>(opts: QueryHandlerOptionsWithEmpty<NonUndefined<TData>, TError>): JSXElementOrNull;
  function QueryHandler<TData>(opts: QueryHandlerOptionsNoEmpty<NonUndefined<TData>, TError>): JSXElementOrNull;
  function QueryHandler<TData>(
    opts:
      | QueryHandlerOptionsNoEmpty<NonUndefined<TData>, TError>
      | QueryHandlerOptionsWithEmpty<NonUndefined<TData>, TError>
  ) {
    const { query } = opts;

    if (query.status === "success") {
      if ("empty" in opts && (query.data == null || (Array.isArray(query.data) && query.data.length === 0))) {
        return opts.empty(query);
      }
      return opts.success(query as QueryObserverSuccessResult<NonNullable<NonUndefined<TData>>, TError>);
    }

    if (query.status === "error") {
      return opts.error?.(query) ?? queryCellOpts.error(query);
    }
    if (query.status === "pending") {
      return opts.pending?.(query) ?? queryCellOpts.pending(query);
    }
    // impossible state
    return null;
  }
  return QueryHandler;
}

export const QueryHandler = createQueryHandler({
  error: () => <Heading>Error loading</Heading>,
  pending: () => (
    <Center css={{ w: "100%", h: 64 }}>
      <Spinner />
    </Center>
  ),
});
