import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { rootRoute } from "../__root";

export const signInRoute = createRoute({
  path: "sign-in",
  getParentRoute: () => rootRoute,
  component: lazyRouteComponent(() => import("./+route.lazy")),
  validateSearch: valibotSearchValidator(
    object({
      from: fallback(optional(string()), undefined),
      to: fallback(optional(string(), "/"), "/"),
    }),
  ),
});
