import { auth } from "@/api/utils";
import { useHasUnreads } from "@/hooks/useHasUnreads";
import { IconBuildingStoreFilled, IconMessage2Filled, IconVideoFilled } from "@/icons";
import { Box, Separator } from "@givenwell/components";
import { CSS, keyframes, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import * as BaseDialog from "@radix-ui/react-dialog";
import {
  IconBuildingStore,
  IconHeart,
  IconHeartFilled,
  IconHelp,
  IconHistory,
  IconLogout,
  IconMessage2,
  IconSpeakerphone,
  IconUser,
  IconVideo,
} from "@tabler/icons-react";
import { Link, LinkComponent, LinkProps, RegisteredRouter, useLocation } from "@tanstack/react-router";
import { ComponentPropsWithoutRef, MouseEventHandler, ReactNode } from "react";

export function DesktopNavDrawer({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) {
  const onClose = () => onOpenChange(false);

  const hasUnreads = useHasUnreads();

  const location = useLocation();

  return (
    <BaseDialog.Root open={open} onOpenChange={onOpenChange}>
      <BaseDialog.Portal>
        <Overlay />
        <Content>
          <NavigationMenuLink
            to="/marketplace"
            onClick={onClose}
            icon={<IconBuildingStore />}
            activeIcon={<IconBuildingStoreFilled />}
          >
            Marketplace
          </NavigationMenuLink>
          <NavigationMenuLink
            to="/messages"
            onClick={onClose}
            icon={<IconMessage2 />}
            activeIcon={<IconMessage2Filled />}
            showBadge={hasUnreads}
          >
            Messages
          </NavigationMenuLink>
          <NavigationMenuLink to="/purchases" onClick={onClose} icon={<IconHistory />}>
            Purchases
          </NavigationMenuLink>
          <NavigationMenuLink to="/resources" onClick={onClose} icon={<IconVideo />} activeIcon={<IconVideoFilled />}>
            Resources
          </NavigationMenuLink>
          <NavigationMenuLink to="/favourites" onClick={onClose} icon={<IconHeart />} activeIcon={<IconHeartFilled />}>
            Favourites
          </NavigationMenuLink>
          <Separator />
          <NavigationMenuLink to="/nominate" search={{ from: location.href }} icon={<IconSpeakerphone />}>
            Nominate
          </NavigationMenuLink>
          <NavigationMenuA href="mailto:support@givenwell.com" icon={<IconHelp />}>
            Contact Us
          </NavigationMenuA>
          <Box css={{ flex: "1 0 0px" }} />
          <NavigationMenuLink to="/settings" onClick={onClose} icon={<IconUser />}>
            Account
          </NavigationMenuLink>
          <NavigationMenuLink
            to="/"
            params={true}
            search={true}
            onClick={e => {
              e.preventDefault();
              auth.logout();
            }}
            icon={<IconLogout />}
          >
            Sign out
          </NavigationMenuLink>
        </Content>
      </BaseDialog.Portal>
    </BaseDialog.Root>
  );
}

const fadeIn = keyframes({
  from: { opacity: 0, backdropFilter: "blur(0px)" },
  to: { opacity: 1, backdropFilter: "blur(0px)" },
});

const fadeOut = keyframes({
  from: { opacity: 1, backdropFilter: "blur(0px)" },
  to: { opacity: 0, backdropFilter: "blur(0px)" },
});

const slideInFromLeft = keyframes({
  from: { transform: "translateX(-100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutToLeft = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-100%)" },
});

const Overlay = styled(BaseDialog.Overlay, {
  backgroundColor: colors.blue900 + "80",
  position: "fixed",
  inset: 0,
  "&[data-state=open]": {
    animation: `${fadeIn()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${fadeOut()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
  zIndex: 999,
});
const Content = styled(BaseDialog.Content, {
  position: "fixed",
  bottom: 0,
  w: "calc(100% - 72px)",
  maxW: 244,
  padding: 16,
  pb: "max(16px, var(--safe-area-inset-bottom))",
  backgroundColor: colors.white,
  color: colors.gray800,
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
  gap: 8,

  left: 0,
  top: "calc(77px + var(--safe-area-inset-top))",
  "&[data-state=open]": {
    animation: `${slideInFromLeft()} 500ms cubic-bezier(.2,0,0,1) forwards`,
  },
  "&[data-state=closed]": {
    animation: `${slideOutToLeft()} 400ms cubic-bezier(.2,0,0,1) forwards`,
  },
});

type NavigationMenuLinkProps<TTo extends string = ""> = LinkProps<RegisteredRouter, string, TTo> & {
  css?: CSS;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  onClick?: MouseEventHandler<"a">;
  showBadge?: boolean;
};

export function NavigationMenuLink<TTo extends string = "">({
  css,
  children,
  icon,
  activeIcon,
  showBadge,
  ...props
}: NavigationMenuLinkProps<TTo>) {
  return (
    <StyledLink {...props} css={css}>
      {({ isActive }) => (
        <>
          {icon && (
            <IconBox>
              {isActive ? activeIcon || icon : icon}
              {showBadge && (
                <Box
                  css={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    rounded: 10,
                    bg: colors.red500,
                    boxShadow: "0 0 0 2px #fff",
                    minW: 10,
                    h: 10,
                  }}
                />
              )}
            </IconBox>
          )}
          {children}
        </>
      )}
    </StyledLink>
  );
}

type NavigationMenuAProps = ComponentPropsWithoutRef<"a"> & {
  css?: CSS;
  icon?: ReactNode;
};

export function NavigationMenuA({ css, children, icon, ...props }: NavigationMenuAProps) {
  return (
    <StyledA {...props} css={css}>
      {icon && <IconBox>{icon}</IconBox>}
      {children}
    </StyledA>
  );
}

const linkCSS: CSS = {
  height: 40,
  display: "flex",
  alignItems: "center",
  px: (40 - 24) / 2,
  gap: (40 - 24) / 2,

  color: colors.gray600,
  fontScale: "sm",
  weight: 400,
  rounded: 8,

  "&.active": {
    weight: 500,
    bg: colors.blue100,
    color: colors.blue800,
  },
};

const StyledLink = styled(Link, linkCSS) as unknown as LinkComponent<"a">;
const StyledA = styled("a", linkCSS);

const IconBox = styled("span", {
  display: "flex",
  items: "center",
  justify: "center",
  position: "relative",
  size: 24,

  color: colors.gray500,
  ".active > &": {
    color: colors.blue800,
  },
});
