import { useDebouncedValue } from "@givenwell/hooks";
import { useQuery } from "@tanstack/react-query";
import { api } from "./utils";

export function useSearchQuery(q: string, country: string) {
  const debouncedQ = useDebouncedValue(q, 500);

  return useQuery({
    queryKey: [
      "address-search",
      {
        q: debouncedQ,
        country,
      },
    ],
    queryFn: async () => {
      if (!debouncedQ) return [];
      return (await api.address.search({ q: debouncedQ, country })).addresses;
    },
    placeholderData: prev => prev,
  });
}

export function useForwardGeocodeQuery(country: string, place: string) {
  const debouncedPlace = useDebouncedValue(place, 500);

  return useQuery({
    queryKey: [
      "forward-geocode",
      {
        country,
        place: debouncedPlace,
      },
    ],
    queryFn: async () => {
      if (!debouncedPlace) return [];
      return (await api.address.forwardGeocode({ country, place: debouncedPlace })).results;
    },
    placeholderData: prev => prev,
  });
}

type Coords = { latitude: number; longitude: number };
export function useReverseGeocodeQuery(coords: Coords | undefined) {
  return useQuery({
    queryKey: ["reverse-geocode", coords],
    queryFn: async () => {
      const [result] = (await api.address.reverseGeocode({ lat: coords!.latitude, lng: coords!.longitude })).results;
      return result;
    },
    enabled: !!coords,
  });
}
