import { CSS, styled } from "@givenwell/stitches";
import { ComponentPropsWithRef, forwardRef } from "react";

export type FormProps = ComponentPropsWithRef<"form"> & {
  css?: CSS;
};

export const Form = forwardRef<HTMLFormElement, FormProps>(function Form({ onSubmit, ...props }, ref) {
  return (
    <Root
      ref={ref}
      {...props}
      onSubmit={e => {
        e.preventDefault();
        onSubmit?.(e);
      }}
    />
  );
});

const Root = styled("form", {});
