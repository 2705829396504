export type ApiErrorResponse = {
  status: number;
  body: ApiError;
};

export type ApiError = {
  code: number;
  message: string;
  data: unknown;
};

export const ApiErrorCode = {
  TimeSlotNotAvailable: 2011,
} as const;
