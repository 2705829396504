import { Box, BoxProps, Flex, FlexProps } from "@givenwell/components";
import { Link, LinkOptions, RegisteredRouter } from "@tanstack/react-router";
import { ForwardedRef, forwardRef } from "react";
import { Button, ButtonProps } from "./Button";
import { IconButton, IconButtonProps } from "./IconButton";

type LinkProps<TTo extends string> = LinkOptions<RegisteredRouter, "/", TTo>;

// Make Generic forward refs work
declare module "react" {
  function forwardRef<T, P = NonNullable<unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

export type BoxLinkProps<TTo extends string> = BoxProps & LinkProps<TTo>;
export const BoxLink = forwardRef(function BoxLink<TTo extends string = "">(
  props: BoxLinkProps<TTo>,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return <Box as={Link as any} ref={ref} {...props} />;
});

export type ButtonLinkProps<TTo extends string> = ButtonProps & LinkProps<TTo>;
export const ButtonLink = forwardRef(function ButtonLink<TTo extends string = "">(
  props: ButtonLinkProps<TTo>,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Button
      as={Link as any}
      // @ts-expect-error polymorphic prop
      ref={ref}
      {...props}
    />
  );
});

export type IconButtonLinkProps<TTo extends string> = IconButtonProps & LinkProps<TTo>;
export const IconButtonLink = forwardRef(function IconButtonLink<TTo extends string = "">(
  props: IconButtonLinkProps<TTo>,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <IconButton
      as={Link as any}
      // @ts-expect-error polymorphic prop
      ref={ref}
      {...props}
    />
  );
});

export type FlexLinkProps<TTo extends string> = FlexProps & LinkProps<TTo>;
export const FlexLink = forwardRef(function FlexLink<TTo extends string = "">(
  props: FlexLinkProps<TTo>,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return <Flex as={Link as any} ref={ref} {...props} />;
});
