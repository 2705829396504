import Cookies from "js-cookie";

type OAuthClientTokens = {
  access_token?: string | undefined;
  refresh_token?: string | undefined;
  id_token?: string | null | undefined;
  expires_in?: number | null | undefined;
};

const DEFAULT_ACCESS_TOKEN_EXPIRE_WINDOW = 15 * 60 * 1000; // 15 min window before making network refresh call.

type ServerAction = "login" | "logout" | "user" | "refresh";

// Generate a random cryptographic string to be used as a state parameter.
function generateRandomString() {
  const array = new Uint32Array(14);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => dec.toString(16)).join("");
}

function createAuthStorage(prefix: string) {
  function createCookieHandler(name: string) {
    return {
      get: () => Cookies.get(`${prefix}${name}`),
      set: (value: string, attrs?: Cookies.CookieAttributes) => Cookies.set(`${prefix}${name}`, value, attrs),
      remove: () => Cookies.remove(`${prefix}${name}`),
    };
  }

  function createLocalStorageHandler(name: string) {
    return {
      get: () => localStorage[`${prefix}${name}`],
      set: (value: string) => (localStorage[`${prefix}${name}`] = value),
      remove: () => delete localStorage[`${prefix}${name}`],
    };
  }

  return {
    accessToken: createCookieHandler(".at"),
    accessTokenExpiry: createCookieHandler(".at_exp"),
    refreshToken: createCookieHandler(".rt"),
    assumedAccessToken: createLocalStorageHandler(".assumed.at"),
    assumedAccessTokenExpiry: createLocalStorageHandler(".assumed.at_exp"),
  };
}

export type CreateAuthOptions = {
  serverUrl: string;
  redirectUri: string;
  logoutUri: string;
  environmentName: string;
  applicationName: string;
  onLogout?(): void;
};

export function createAuth(options: CreateAuthOptions) {
  const storagePrefix = `${options.environmentName}.${options.applicationName}`;
  const storage = createAuthStorage(storagePrefix);
  let isAuthenticated = !!storage.accessToken.get();
  let refreshStatus: "loading" | "idle" = "idle";

  function generateServerUrl(serverAction: ServerAction, queryParams?: Record<string, string>) {
    const query = new URLSearchParams(queryParams);
    const queryString = query.toString().length > 0 ? `?${query.toString()}` : "";
    const path = `/auth/${options.applicationName}/${serverAction}`;
    return `${options.serverUrl}${path}${queryString}`;
  }

  function getLoginUrl(state = "", redirect_uri?: string) {
    const stateParam = `${generateRandomString()}:${state}`;
    const fullUrl = generateServerUrl("login", {
      redirect_uri: redirect_uri ?? options.redirectUri,
      state: stateParam,
    });
    return fullUrl;
  }

  function login(state = "", redirect_uri?: string) {
    window.location.assign(getLoginUrl(state, redirect_uri));
  }

  function logout() {
    sessionStorage.clear();

    // Clear local cookies.
    storage.accessToken.remove();
    storage.accessTokenExpiry.remove();
    storage.refreshToken.remove();

    options.onLogout?.();

    const queryParams = {
      post_logout_redirect_uri: options.logoutUri,
    };

    const fullUrl = generateServerUrl("logout", queryParams);
    window.location.assign(fullUrl);
  }

  function getIsRefreshRequired() {
    const refreshToken = storage.refreshToken.get();
    if (!refreshToken) {
      return false;
    }
    const accessTokenExpires = storage.accessTokenExpiry.get();
    const timeWindow = DEFAULT_ACCESS_TOKEN_EXPIRE_WINDOW;
    if (accessTokenExpires === undefined || Number(accessTokenExpires) < Date.now() + timeWindow) {
      return true;
    }
    return false;
  }

  /**
   * Refreshes the access token if required.
   */
  async function refreshInBackgroundIfNecessary(force = false) {
    if (!getIsRefreshRequired() && !force) {
      return true;
    }
    if (refreshStatus === "loading") {
      return true;
    }
    const refreshToken = storage.refreshToken.get();
    if (!refreshToken) {
      if (isAuthenticated) {
        logout();
      }
      return false;
    }

    refreshStatus = "loading";
    // This request automatically sets the cookies on success.
    try {
      const response = await fetch(generateServerUrl("refresh"), {
        method: "POST",
        headers: { "content-type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      });

      if (!response.ok) {
        logout();
        return false;
      }

      if (response.status >= 200 && response.status <= 299) {
        const data = (await response.json()) as OAuthClientTokens;
        handleLoggedIn(data);
        return true;
      }
    } catch (e) {
      // Failed to refresh token.
      logout();
      return false;
    } finally {
      refreshStatus = "idle";
    }
  }

  function hasAccessToken() {
    return isAuthenticated;
  }

  /**
   * @returns Access token. If an assumed role access token is available, it will be returned.
   */
  async function getAccessToken() {
    await refreshInBackgroundIfNecessary();

    const assumedRoleAccessToken = storage.assumedAccessToken.get();

    if (assumedRoleAccessToken) {
      // Check validity of assumed role access token.
      const assumedRoleAccessTokenExpiry = parseFloat(storage.assumedAccessTokenExpiry.get());
      if (assumedRoleAccessTokenExpiry > Date.now()) {
        return assumedRoleAccessToken;
      } else {
        stopAssumingRole();
      }
    }

    const accessToken = storage.accessToken.get();
    return accessToken ?? "";
  }

  /**
   * Post login function for getting logged in outside the normal flow, e.g. accepting invitation and creating a user.
   */
  function handleLoggedIn(data: OAuthClientTokens) {
    const expiresInSecs = data.expires_in ?? 60 * 60;
    const expiresMillis = Date.now() + 1000 * expiresInSecs;
    const expiryDate = new Date(expiresMillis);

    storage.accessToken.set(data.access_token ?? "", { expires: expiryDate });
    storage.refreshToken.set(data.refresh_token ?? "", { expires: 30 });
    storage.accessTokenExpiry.set(expiresMillis.toString(), { expires: expiryDate });
    isAuthenticated = true;
  }

  function assumeRole(accessToken: string) {
    storage.assumedAccessToken.set(accessToken);
    storage.assumedAccessTokenExpiry.set((Date.now() + 3600 * 1000).toString());
  }
  function stopAssumingRole() {
    storage.assumedAccessToken.remove();
    storage.assumedAccessTokenExpiry.remove();
  }

  return {
    hasAccessToken,
    getAccessToken,
    refresh: refreshInBackgroundIfNecessary,
    getRefreshRequired: getIsRefreshRequired,

    getLoginUrl,
    login,
    logout,

    assumeRole,
    stopAssumingRole,

    handleLoggedIn,
  };
}

export type Auth = ReturnType<typeof createAuth>;
