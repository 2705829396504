import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const meQueryOptions = queryOptions({
  queryKey: ["me"],
  queryFn: async () => {
    const res = await api.auth.getMarketplaceUserInfo();
    import("@/third-party")
      .then(module => {
        module.identifyUser(res.id, res.email, res.given_name + " " + res.family_name);
      })
      .catch(console.error);
    return res;
  },
});
export function useMeQuery() {
  return useQuery(meQueryOptions);
}
useMeQuery.prefetch = () => queryClient.prefetchQuery(meQueryOptions);

export function useUserId() {
  const { data } = useMeQuery();
  return data?.id || "";
}
export function getUserId() {
  const data = queryClient.getQueryData(meQueryOptions.queryKey);
  return data?.id || "";
}
