import { Flex, FlexProps } from "@givenwell/components";

export function PageInset(props: FlexProps) {
  return (
    <Flex
      {...props}
      css={{
        flexDir: "column",
        pl: "max(16px, var(--safe-area-inset-left))",
        pr: "max(16px, var(--safe-area-inset-right))",
        ...props.css,
      }}
    />
  );
}

export function ContentBlock(props: FlexProps) {
  return (
    <Flex
      {...props}
      css={{
        flexDir: "column",
        pl: "max(16px, var(--safe-area-inset-left))",
        pr: "max(16px, var(--safe-area-inset-right))",
        py: 16,
        ...props.css,
      }}
    />
  );
}
