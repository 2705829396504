import { create, useStore } from "zustand";

const SEARCH_RADIUS_LOCAL_STORAGE_KEY = "search-radius-meters";
const DEFAULT_SEARCH_RADIUS = 40000;

const storedRadiusString = localStorage.getItem(SEARCH_RADIUS_LOCAL_STORAGE_KEY);
let initialSearchRadius = DEFAULT_SEARCH_RADIUS;

if (storedRadiusString) {
  const parsed = parseInt(storedRadiusString, 10);
  if (isFinite(parsed)) {
    initialSearchRadius = parsed;
  }
}

const searchRadiusStore = create<{ searchRadius: number; setSearchRadius: (radius: number) => void }>(set => ({
  searchRadius: initialSearchRadius,
  setSearchRadius: radius => {
    set({ searchRadius: radius });
    localStorage.setItem(SEARCH_RADIUS_LOCAL_STORAGE_KEY, radius.toString());
  },
}));

export function useSearchRadius() {
  return useStore(searchRadiusStore);
}
