import { PostSupplierEnquiryMessageBody } from "@givenwell/marketplace-api";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const supplierQueryOptions = (supplierId: string) =>
  queryOptions({
    queryKey: ["supplier", supplierId],
    queryFn: async () => {
      return await api.suppliers.getSupplierForMarketplace({ supplierId });
    },
  });
export function useSupplierQuery(supplierId: string) {
  return useQuery(supplierQueryOptions(supplierId));
}
useSupplierQuery.prefetch = (supplierId: string) => queryClient.prefetchQuery(supplierQueryOptions(supplierId));

const supplierListingsQueryOptions = (supplierId: string) =>
  queryOptions({
    queryKey: ["supplier", supplierId, "listings"],
    queryFn: async () => {
      return await api.suppliers.getListingsFromSupplier({ supplierId });
    },
  });
export function useSupplierListingsQuery(supplierId: string) {
  return useQuery(supplierListingsQueryOptions(supplierId));
}
useSupplierListingsQuery.prefetch = (supplierId: string) =>
  queryClient.prefetchQuery(supplierListingsQueryOptions(supplierId));

export function usePostSupplierEnquiryMutation(supplierId: string) {
  return useMutation({
    mutationFn: async (requestBody: PostSupplierEnquiryMessageBody) => {
      return await api.suppliers.postSupplierEnquiry({ supplierId, requestBody });
    },
  });
}

function supplierLegalQueryOptions(supplierId: string) {
  return queryOptions({
    queryKey: ["supplier", supplierId, "legal"],
    queryFn: async () => {
      return await api.suppliers.getLegalForMember({ supplierId });
    },
  });
}

export function useSupplierLegalQuery(supplierId: string) {
  return useQuery(supplierLegalQueryOptions(supplierId));
}
