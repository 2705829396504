import { useResourcesHomeQuery } from "@/api/resources";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { resourcesRoute } from "./+route";

export const resourcesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => resourcesRoute,
  loader: () => {
    useResourcesHomeQuery.prefetch();
  },
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
