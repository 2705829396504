import { infiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import { getUserId, useMeQuery, useUserId } from "./auth";
import { api, queryClient } from "./utils";

const infiniteTransactionsQueryOptions = (userId: string) =>
  infiniteQueryOptions({
    queryKey: ["transactions"],
    queryFn: async ({ pageParam }) => {
      const response = await api.transactions.getTransactionsForMember({
        memberId: userId,
        pageNum: pageParam,
        pageSize: 25,
      });
      return response;
    },
    enabled: !!userId,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.has_more) return undefined;
      return allPages.length + 1;
    },
  });
export function useInfiniteTransactionsQuery() {
  const userId = useUserId();

  return useInfiniteQuery(infiniteTransactionsQueryOptions(userId));
}
useInfiniteTransactionsQuery.prefetch = async () => {
  await useMeQuery.prefetch();
  const userId = getUserId();
  await queryClient.prefetchInfiniteQuery(infiniteTransactionsQueryOptions(userId));
};
