import { useEffect } from "react";
import { useEvent } from "./useEvent";

export function useInterval(callback: () => void, delay: number | null | undefined) {
  const event = useEvent(callback);

  useEffect(() => {
    if (!delay) {
      return;
    }

    const id = setInterval(event, delay);

    return () => clearInterval(id);
  }, [delay]);
}
