import { useInfinitePurchaseHistoryQuery } from "@/api/purchases";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { valibotSearchValidator } from "@tanstack/router-valibot-adapter";
import { fallback, object, optional, string } from "valibot";
import { purchasesRoute } from "./+route";

const searchSchema = object({
  showReceipt: fallback(optional(string()), undefined),
});

export const purchasesIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => purchasesRoute,
  loader: () => {
    useInfinitePurchaseHistoryQuery.prefetch();
  },
  validateSearch: valibotSearchValidator(searchSchema),
  component: lazyRouteComponent(() => import("./+index.lazy")),
});
