import { Box, Flex } from "@givenwell/components";
import { keyframes, styled } from "@givenwell/stitches";
import { colors, easing } from "@givenwell/theme";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { IconX } from "@tabler/icons-react";
import { ReactNode } from "react";

export type BottomSheetProps = DialogPrimitive.DialogProps & {
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  rightAction?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
};

export function BottomSheet({
  children,
  title,
  description,
  rightAction,
  content,
  footer,
  ...props
}: BottomSheetProps) {
  const showHeaderLine = title || rightAction;
  const headerCss = showHeaderLine ? { borderBottom: `1px solid ${colors.gray200}` } : undefined;

  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <Overlay />
        <Content>
          <Header css={headerCss}>
            <Flex css={{ items: "center", minH: 64 }}>
              <Flex css={{ flex: "1 0 0px" }}>
                <Close>
                  <IconX />
                </Close>
              </Flex>
              <Title>{title}</Title>
              <Flex css={{ flex: "1 0 0px", justifyContent: "flex-end" }}>{rightAction}</Flex>
            </Flex>
            {description && <Description>{description}</Description>}
          </Header>
          <Body>
            {content}
            {!footer && (
              <Box
                css={{
                  height: "max(var(--safe-area-inset-bottom), 20px)",
                }}
              />
            )}
          </Body>
          {footer && <Footer>{footer}</Footer>}
        </Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

export function BottomSheetClose(props: DialogPrimitive.DialogCloseProps) {
  return <DialogPrimitive.Close asChild {...props} />;
}

const overlayOpen = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const overlayClose = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});

const Overlay = styled(DialogPrimitive.Overlay, {
  bg: colors.blue900 + "80",
  position: "fixed",
  inset: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  overflow: "auto",
  zIndex: 1000,

  "&[data-state='open']": {
    animation: `${overlayOpen()} 500ms ${easing.standard}`,
  },
  "&[data-state='closed']": {
    animation: `${overlayClose()} 400ms ${easing.standard}`,
  },
});

const contentOpen = keyframes({
  from: {
    transform: "translateY(100%)",
  },
  to: {
    transform: "translateY(0)",
  },
});

const contentClose = keyframes({
  from: {
    transform: "translateY(0)",
  },
  to: {
    transform: "translateY(100%)",
  },
});

const Content = styled(DialogPrimitive.Content, {
  bg: "white",
  w: "100%",
  mx: "auto",
  maxW: 680,
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  maxH: "calc(100% - var(--safe-area-inset-top, 0px) - 10px)",
  roundedT: 10,
  zIndex: 1000,
  display: "flex",
  flexDir: "column",

  "&[data-state='open']": {
    animation: `${contentOpen()} 500ms ${easing.standard}`,
  },
  "&[data-state='closed']": {
    animation: `${contentClose()} 400ms ${easing.standard}`,
  },
});

const Header = styled("div", {
  // d: "flex",
  minH: 64,
  // items: "center",
  px: 4,
});
const Body = styled("div", {
  px: 20,
  flexShrink: 1,
  overflowY: "auto",
});
const Footer = styled("div", {
  px: 20,
  pb: "max(var(--safe-area-inset-bottom), 20px)",
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.04)",
});

const Close = styled(DialogPrimitive.Close, {
  size: 48,
  minSize: 48,
  d: "flex",
  items: "center",
  justify: "center",
});
const Title = styled(DialogPrimitive.Title, {
  fontScale: "md",
  fontWeight: 700,
});

const Description = styled(DialogPrimitive.Description, {
  fontScale: "sm",
  fontWeight: 400,
  color: colors.gray700,
  px: 16,
  pb: 8,
  align: "center",
});

export function DialogClose(props: DialogPrimitive.DialogCloseProps) {
  return <DialogPrimitive.Close asChild {...props} />;
}
