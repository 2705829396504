import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const resourcesHomeQueryOptions = queryOptions({
  queryKey: ["resources", "list"],
  queryFn: async () => {
    const home = await api.resources.getResourcesHome();
    return home.groups || [];
  },
});
export function useResourcesHomeQuery() {
  return useQuery(resourcesHomeQueryOptions);
}
useResourcesHomeQuery.prefetch = () => queryClient.prefetchQuery(resourcesHomeQueryOptions);

const resourceQueryOptions = (resourceId: string | undefined) =>
  queryOptions({
    queryKey: ["resources", "detail", resourceId],
    queryFn: async () => {
      const resource = await api.resources.getResourceForMarketplace({ resourceId: resourceId! });
      return resource;
    },
    enabled: !!resourceId,
  });
export function useResourceQuery(resourceId: string | undefined) {
  return useQuery(resourceQueryOptions(resourceId));
}
useResourceQuery.prefetch = (resourceId: string | undefined) => {
  return queryClient.prefetchQuery(resourceQueryOptions(resourceId));
};
