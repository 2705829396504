import { CSS, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { ComponentPropsWithRef, useContext } from "react";
import { FieldContext } from ".";

export type DescriptionProps = ComponentPropsWithRef<"p"> & { css?: CSS };

export function Description(props: DescriptionProps) {
  const { descriptionProps } = useContext(FieldContext);
  return <Root {...descriptionProps} {...props} />;
}

const Root = styled("div", {
  d: "block",
  fontScale: "xs",
  weight: 400,
  color: colors.gray500,

  mr: 12,
});
