export function handleDynamicImportErrors() {
  const LAST_RELOAD_KEY = "last-reload";

  // If an error happened this close to the last one then there is probably a deeper issue, ignore it to prevent an infinite loop
  const RELOAD_GRACE_PERIOD = 5 * 1000;

  function attemptReload(event: VitePreloadErrorEvent) {
    const lastReload = localStorage.getItem(LAST_RELOAD_KEY);
    if (lastReload) {
      const lastReloadTime = parseInt(lastReload, 10);
      if (Date.now() - lastReloadTime < RELOAD_GRACE_PERIOD) {
        return;
      }
    }
    localStorage.setItem(LAST_RELOAD_KEY, Date.now().toString());
    window.location.reload();
  }

  window.addEventListener("vite:preloadError", attemptReload);
}
